import React from 'react';
import { graphql } from 'gatsby';

import {
    errorPage,
    title as titleClass,
    content as contentClass,
    background,
    errorCodeWrapper,
    errorHeading,
    button,
} from './error-page.module.scss';
import { IErrorPage } from '../models/error-page.model';
import useTranslations from '../hooks/use-translations';
import BrokenTape from '../assets/images/svg/broken-tape.svg';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import Button from '../components/atoms/button';
import Markdown from '../components/hoc/markdown';

export interface IErrorPageProps {
    data: {
        errorPage: IErrorPage;
    };
}

const ErrorPage: React.FC<IErrorPageProps> = ({ data }) => {
    const { title, content, errorCode } = data.errorPage;
    const { backHomeButton } = useTranslations('ErrorPage');
    return (
        <MainLayout className={errorPage}>
            <Heading Tag="h1" numberOfStyle={4}>
                Error
            </Heading>
            <div className={errorCodeWrapper}>
                <BrokenTape className={background} />
                <Heading numberOfStyle={1} className={errorHeading}>
                    {errorCode}
                </Heading>
            </div>
            <Heading className={titleClass} numberOfStyle={4} isRegular={false}>
                {title}
            </Heading>
            <Markdown className={contentClass}>{content}</Markdown>
            <Button className={button} as="link" to="/" isOutlined theme="accent">
                {backHomeButton}
            </Button>
        </MainLayout>
    );
};

export const query = graphql`
    query ($errorPageId: Int) {
        errorPage(errorPageId: { eq: $errorPageId }) {
            errorCode
            title
            content
        }
    }
`;

export default ErrorPage;
